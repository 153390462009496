import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'

import { createGlobalStyle } from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"
import Image from 'gatsby-image'
import Theme from '../Theme'

const GlobalStyle = createGlobalStyle`
  body {
    background: #fee343;
    color: #c03994;
  }`;


const StyledHeader = styled.header`
  margin: auto;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .gatsby-image-wrapper {
    max-width: 100%;
  }
`

const StyledWrapper = styled.div`
  color: #c03994;
  margin: 0;
  font-family: 'Raleway', sans-serif;

  .site-logo {
    max-width: 220px;
    margin: 2rem auto;

    @media screen and (min-width: 580px) {
      margin: 1rem 0 1rem 1rem;
    }
  }
  
  a {
    color: #c03994;
  }
`

const StyledSocialNav = styled.nav`
  padding-left: 2rem;

  @media screen and (min-width: 769px) {
    padding-left: 0;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  a:last-child i {
    margin-right: 0;
  }
  
  i {
    font-size: 2.4rem;
    margin-right: 1rem;
    color: #c03994;
  }
`

const StyledNav = styled.nav`
  display: flex;
  margin-right: 1rem;

  .menu {
    padding: 0;

    @media screen and (min-width: 769px) {
      display: flex;
      list-style: none;
    }

    li {
      list-style: none;

      .topLevel {
        padding-left: 2rem;
        cursor: pointer;
        line-height: 2.4rem;
        font-size: 1.5rem;
        text-decoration: none;

        @media screen and (min-width: 1280px) {
          font-size: 1.8rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .dropdown a {
        font-size: 14px;
        display: block;
        padding: 6px 20px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`

const Layout = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query Logo {
      logo: file(absolutePath: { regex: "/logo.jpg/" }) {
        childImageSharp {
          fixed(width: 220) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  let logo = (
    <h1 className="site-logo">
      <Link
        to={`/`}
      >
        <Image
          fixed={data.logo.childImageSharp.fixed}
          alt={title}
        />
      </Link>
    </h1>
  )

  return (
    <Theme>
      <GlobalStyle />
      <StyledWrapper>
        <StyledHeader>
          {logo}

          <StyledSocialNav>
            <Link to="https://www.instagram.com/sonia_newtownhair">
              <i className="fab fa-instagram-square"></i>
            </Link>

            <Link to="https://www.facebook.com/hairnewtown/">
              <i className="fab fa-facebook-square"></i>
            </Link>

            <Link to="https://twitter.com/NewtownHair">
              <i className="fab fa-twitter-square"></i>
            </Link>

          </StyledSocialNav>

          <StyledNav>
            <ul className="menu">
              <li>
                <a href="/" className="toplevel" className="topLevel">Home</a>
              </li>
              <li>
                <a className="topLevel" href="https://bookings.gettimely.com/newtownhair/bb/book">Book with Sonia</a>
              </li>
              <li>
                <a href="/contact" className="topLevel">Contact</a>
              </li>
              <li>
                <a href="tel:+61352296876" className="topLevel">Ph: (03) 5229 6876</a>
              </li>
            </ul>
          </StyledNav>
        </StyledHeader>
        <div>
          <main>{children}</main>
        </div>
      </StyledWrapper>
    </Theme>
  )
}

export default Layout
