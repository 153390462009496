import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    light: '#fff',
    dark: '#373736',
    accent: ''
  }
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    min-height: 100%;
  }

  .gatsby-resp-iframe-wrapper {
    max-width: 50vw;
    max-height: 50vw;
  }

  main {
    ${'' /* padding: 10px; */}
  }

  .dropdownTarget {
    position: relative;
  }
  
  .dropdownTarget:hover .dropdown {
    display: block;
  }

  ul.dropdown {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 2rem;
    display: none;
    padding: 10px 0;
    background: white;
    font-size: 14px;
  }

`

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalStyle />
  </ThemeProvider>
);

export default Theme;